import React from "react";
import { connect } from "react-redux";
import { evoucherFetch, evoucherBarcodeFetch } from "./../actions/EvoucherActions"
import { EVOUCHER_MAZDA_2021001, EVOUCHER_HONDA_2021001 } from "./../constants/Systems";
import Mazda2021001 from "./templates/Mazda2021001";
import Honda2021001 from "./templates/Honda2021001";
class Evoucher extends React.Component {

    constructor(props) {
        super(props);
        this.state = { term: false, redeem: false };
    }

    componentDidMount() {
        const { match } = this.props
        if (match.params.id) {
            this.props.evoucherFetch(match.params.id);
        }
        if (match.params.barcode) {
            this.props.evoucherBarcodeFetch(match.params.barcode);
        }
        document.getElementById('body').style.overflow = 'auto';
        document.getElementById('body').style.backgroundColor = 'white';
    }

    render() {
        const { evoucher } = this.props;
        console.log(evoucher);

        let evoucherComponent = null;
        if (evoucher.group == EVOUCHER_MAZDA_2021001) {
            evoucherComponent = <Mazda2021001 />;
        } else if (evoucher.group == EVOUCHER_HONDA_2021001) {
            evoucherComponent = <Honda2021001 />;
        }

        return (
            <div className="container  bg-white" >
                {evoucherComponent}
            </div>
        )
    }
}

const mapStateToProps = ({ evoucher: { evoucher } }) => {
    return { evoucher };
};

export default connect(
    mapStateToProps, { evoucherFetch, evoucherBarcodeFetch }
)(Evoucher);