export const signInAdminFormFields = [
	{ label: "user.email", name: "email", type: "email", required: true, margin: "normal", className: "mt-1 my-sm-3" },
	{ label: "user.password", name: "password", type: "password", required: true, margin: "normal", className: "mt-1 my-sm-3" }
];

export const userSearchFormFields = [
	{ label: "user.first_name", name: "first_name", type: "text", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.last_name", name: "last_name", type: "text", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.email", name: "email", type: "text", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{
		label: "user.role", name: "role", type: "dropdown", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		options: [
			{ display: "user.role.root", value: "root" },
			{ display: "user.role.admin", value: "admin" }
		]
	},
]

export const userFormFields = [
	{ label: "user.first_name", name: "first_name", type: "text", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.last_name", name: "last_name", type: "text", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.email", name: "email", type: "text", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.password", name: "password", type: "password", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.mobile", name: "mobile", type: "text", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.position_title", name: "position_title", type: "text", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{
		label: "user.active_flag", name: "active_flag", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		options: [
			{ display: "user.active_flag.1", value: "1" },
			{ display: "user.active_flag.0", value: "0" },
		]
	},
	{
		label: "user.role", name: "role", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		options: [
			{ display: "user.role.root", value: "root" },
			{ display: "user.role.admin", value: "admin" }
		]
	},
	{ label: "evoucher.group", type: "header", className: "mt-3" },
	{ label: "evoucher.group.msi-mazda", name: "groups.msi-mazda", type: "checkbox", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "evoucher.group.honda-evoucher-2021001", name: "groups.honda-evoucher-2021001", type: "checkbox", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
]

export const reportEvoucherResponserateSearchFormFields = [
	{
		label: "report.evoucher.responserate.month", name: "month", type: "dropdown", required: true, margin: "normal", className: "col-4 mt-0 mb-2",
		options: [
			{ display: "month.01", value: "01" },
			{ display: "month.02", value: "02" },
			{ display: "month.03", value: "03" },
			{ display: "month.04", value: "04" },
			{ display: "month.05", value: "05" },
			{ display: "month.06", value: "06" },
			{ display: "month.07", value: "07" },
			{ display: "month.08", value: "08" },
			{ display: "month.09", value: "09" },
			{ display: "month.10", value: "10" },
			{ display: "month.11", value: "11" },
			{ display: "month.12", value: "12" },
		]
	},
	{
		label: "report.evoucher.responserate.year", name: "year", type: "dropdown", required: true, margin: "normal", className: "col-4 mt-0 mb-2",
		options: [
			{ display: "year.2020", value: "2020" },
			{ display: "year.2021", value: "2021" },
			{ display: "year.2022", value: "2022" },
			{ display: "year.2023", value: "2023" },
		]
	},
	{
		label: "report.evoucher.responserate.group", name: "group", type: "dropdown", required: true, margin: "normal", className: "col-4 mt-0 mb-2",
		options: []
	}
]